import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import css from "./AppLoader.css"

const AppLoader = () => {
    return (
      <Container>
        <Row>
          <Col xs={12} className="cryBabe">
            <Image
              src="/cry_babe.gif"
              alt="Description"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    );
};

export default AppLoader;
