import React, { useState, useRef } from 'react';

function CustomSelect({ options, onChange, getOptionLabel, defaultValue }) {
  const [selectedOption, setSelectedOption] = useState(
    defaultValue || options[0]
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Добавление слушателя для кликов вне компонента
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <div
        className="custom-select__selected"
        onClick={handleToggleDropdown}
        style={{
          border: "1px solid #c7cbd1",
          padding: "10px",
          paddingLeft: "5px",
          cursor: "pointer",
          userSelect: "none",
          //   backgroundColor: "white",
          width: "100%",
        }}
      >
        {getOptionLabel(selectedOption)}
      </div>
      {isOpen && (
        <div
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <ul
            className="custom-select__options"
            style={{
              listStyleType: "none",
              padding: "0",
              margin: "0",
              border: "1px solid #ccc",
              position: "absolute",
              left: "0",
              right: "0",
              backgroundColor: "#d1e0d5",
              zIndex: 1,
              //   width: "auto",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  borderBottom: "2px solid #111111",
                }}
              >
                {getOptionLabel(option)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomSelect;