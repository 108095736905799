import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TableTitle = (props) => {
  return (
    <Container fluid>
      <Row className="mt-1 text-center fw-bold  d-lg-flex d-none">
        <Col xs={12} xl={1}>
          Имя файла
        </Col>
        <Col xs={12} xl={2}>
          Видео @ FPS
        </Col>
        <Col xs={12} xl={4}>
          Звуковая дорожка
        </Col>
        <Col xs={12} xl={1}>
          Субтитры
        </Col>
        <Col xs={12} xl={2}>
          Варианты кодирования
        </Col>
        <Col xs={12} xl={1}>
          Приоритет
        </Col>
        <Col xs={12} xl={1}>
          8 of 8
        </Col>
      </Row>
    </Container>
  );
};

export default TableTitle

