import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { Container, Row, Col } from "react-bootstrap";
import CustomSelect from "./CustomSelect";
import css from "./WorkTable.css";



const WorkTable = (props) => {
  let startData = props.data;
  let formatData = props.format;
  let formatSubtitles = props.formatSubtitles;
  let formatSound = props.formatSound;
  let sendData = {};
  let frameRate = undefined;
  const isEven = props.index % 2 === 0;

  const optionsDataTitle = [];
  const optionsDataVideo = [];
  const optionsDataSound = [{ value: "-1", label: "none" }];
  const optionsDataSubtitle = [{ value: "-1", label: "none" }];
  const optionsDataFormat = [];
  const optionsDataPriority = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];
  const optionsDataFormatSubtitles = [];
  const optionsDataFormatSound = [];

  const [colorButtonSend, setColorButtonSend] = useState("none");
  const [displayModal, setDisplayModal] = useState(false);
  const [disabledButtonSend, setDisabledButtonSend] = useState(false);
  const [firstRendering, setFirstRendering] = useState(true);
  const [sendDataNew, setSendDataNew] = useState({
    name: "",
    video_id: "",
    audio_id: "",
    audio2_id: "",
    sub_force_id: "",
    sub2_force_id: "",
    sub3_force_id: "",
    r_frame_rate: 0,
    format: "",
    priority: "0",
    formatSound: "",
    formatSubtitles1: "",
    formatSubtitles2: "",
  });

  const divRef = useRef(null);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      console.log("Clicked outside, hiding div");
      setDisplayModal(false);
    } else {
      console.log("Clicked inside, keeping div visible");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  let MyStyleModal = {
    display: displayModal,
    position: "fixed",
    zIndex: "1",
    left: "0",
    top: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  let MyStyleTitle = {};

  const ButtonClick = () => {
    console.log("sendDataNew was send " + JSON.stringify(sendDataNew));
    axios
      .post("https://api-dev.portal.idc.md/api/codec/add", sendDataNew)
      .then((response) => {
        console.log("transfer ok");
      })
      .catch((error) => {
        console.log("transfer bad");
      });
    // после нажатия на кнопку красим ее в желтый и запрещаем повторное нажатие
    setColorButtonSend("yellow");
    setDisabledButtonSend(true);
  };
  const openModal = () => {
    setDisplayModal(true);
  };

  const closeModal = () => {
    setDisplayModal(false);
  };

  // раскрашиваем некоторые пункты в select добавляя по условию span к label
  const getOptionLabelFormat = (option) => {
    if (
      option.value === "1082-1080-normal-low-hls@remove" ||
      option.value === "1082@remove"
    ) {
      return <span className="optionRed">{option.label}</span>;
    }
    return option.label;
  };

  // раскрашиваем имена файлов в соответствии с условием
  const getOptionLabelTitle = (option) => {
    let index = option.indexOf("_1082");
    if (index !== -1) {
      MyStyleTitle.color = "red";
    }
  };

  const onChangeVideo = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      video_id: event.value,
    }));
    sendData.video_id = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const onChangeAudio = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      audio_id: event.value,
    }));
    sendData.audio_id = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };

  const onChangeAudio2 = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      audio2_id: event.value,
    }));
    sendData.audio2_id = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const onChangeSubtitles = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      sub_force_id: event.value,
    }));
    sendData.sub_force_id = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const onChangeSubtitles2 = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      sub2_force_id: event.value,
    }));
    sendData.sub2_force_id = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const onChangeSubtitles3 = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      sub3_force_id: event.value,
    }));
    sendData.sub3_force_id = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const onChangeDataFormat = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      format: event.value,
    }));
    sendData.format = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const onChangePriority = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      priority: event.value,
    }));
    sendData.priority = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const onChangeFormatSound = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      formatSound: event.value,
    }));
    sendData.formatSound = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const onChangeFormatSubtitles1 = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      formatSubtitles1: event.value,
    }));
    sendData.formatSubtitles1 = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const onChangeFormatSubtitles2 = (event) => {
    setSendDataNew((prevState) => ({
      ...prevState,
      formatSubtitles2: event.value,
    }));
    sendData.formatSubtitles2 = event.value;
    console.log("sendData changed " + JSON.stringify(sendData));
  };
  const setSendData = () => {
    console.log("попали  в setSendData")
    setSendDataNew((prevState) => ({
      ...prevState,
      name: optionsDataTitle[0].value,
      video_id: optionsDataVideo[0].value,
      audio_id: optionsDataSound[1].value,
      audio2_id: optionsDataSound[0].value,
      sub_force_id: optionsDataSubtitle[0].value,
      sub2_force_id: optionsDataSubtitle[0].value,
      sub3_force_id: optionsDataSubtitle[0].value,
      r_frame_rate: frameRate,
      format: optionsDataFormat[0].value,
      priority: optionsDataPriority[0].value,
      formatSound: optionsDataFormatSound[0].value,
      formatSubtitles1: optionsDataFormatSubtitles[0].value,
      formatSubtitles2: optionsDataFormatSubtitles[0].value,
    }));
  };

  if (formatSound) {
    formatSound.map((el) => {
      optionsDataFormatSound.push({ value: el, label: el });
    });
  }

  if (formatSubtitles) {
    formatSubtitles.map((el) => {
      optionsDataFormatSubtitles.push({ value: el, label: el });
    });
  }

  if (startData && formatData) {
    formatData.map((el) => {
      optionsDataFormat.push({
        value: el,
        label: el,
      });
    });

    // прогоняем данные map - в зависимости от key запихиваем в нужный массив данные
    startData.map((element) => {
      switch (element.key) {
        case "title":
          optionsDataTitle.push({
            value: JSON.stringify(element.name).slice(1).slice(0, -1),
            label: JSON.stringify(element.name).slice(1).slice(0, -5),
          });
          getOptionLabelTitle(element.name);
          break;
        case "video":
          // if (
          //   element.codec_name === "h264" ||
          //   element.codec_name === "hevc"
          // ) {
          optionsDataVideo.push({
            value: JSON.stringify(element.index),
            label:
              JSON.stringify(element.width) +
              " : " +
              JSON.stringify(element.height) +
              "@" +
              JSON.stringify(element.r_frame_rate).slice(1).slice(0, -1),
          });
          frameRate = element.r_frame_rate;
          // }
          break;
        case "audio":
          optionsDataSound.push({
            value: JSON.stringify(element.index),
            label:
              JSON.stringify(element.codec_name) +
              "(" +
              JSON.stringify(element.lang) +
              ")" +
              JSON.stringify(element.title) +
              " channels: " +
              JSON.stringify(element.channels),
          });
          break;
        case "subtitle":
          optionsDataSubtitle.push({
            value: JSON.stringify(element.index),
            label:
              JSON.stringify(element.lang).slice(1).slice(0, -1) +
              `${element.forced ? "+" : "-"}` +
              JSON.stringify(element.title).slice(1).slice(0, -1),
          });
          break;
        default:
      }
    });
  }
  // прописываем в стейт данные
  if (firstRendering) {
    setSendData();
    setFirstRendering(false);
  }

  console.log("firstRender " + firstRendering);
  console.log("sendDataNew " + JSON.stringify(sendDataNew));
  console.log("index and isEven " + props.index + " " + isEven);

  // console.log("after render sendData " + JSON.stringify(sendData));

  return (
    <>
      <div>
        <div
          style={{
            display: displayModal ? "block" : "none",
            position: "fixed",
            zIndex: "1",
            left: "0",
            top: "0",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div ref={divRef}>
            <div className="myModalContent">
              <div className="modalTableContainer">
                <Container>
                  <Row className="fw-bold">
                    <Col xs={12} xl={6} className="p-1">
                      Доп. звуковая дорожка
                    </Col>
                    <Col xs={12} xl={6} className="p-1 d-none d-sm-block">
                      Перевод
                    </Col>
                  </Row>
                  <Row className="solid-line text-start p-1">
                    <Col xs={12} xl={6}>
                      <CustomSelect
                        defaultValue={optionsDataSound[0]}
                        onChange={onChangeAudio2}
                        options={optionsDataSound}
                        getOptionLabel={getOptionLabelFormat}
                      />
                    </Col>
                    <Col
                      xs={12}
                      className="p-1 fw-bold text-center d-block d-sm-none"
                    >
                      Перевод
                    </Col>
                    <Col xs={12} xl={6}>
                      <CustomSelect
                        defaultValue={optionsDataFormatSound[0]}
                        onChange={onChangeFormatSound}
                        options={optionsDataFormatSound}
                        getOptionLabel={getOptionLabelFormat}
                      />
                    </Col>
                  </Row>
                  <Row className="fw-bold">
                    <Col xs={12} xl={6} className="p-1 ">
                      Доп. субтитры
                    </Col>
                    <Col xs={12} xl={6} className="p-1 d-none d-sm-block">
                      Перевод
                    </Col>
                  </Row>
                  <Row className="text-start p-1">
                    <Col xs={12} xl={6}>
                      <CustomSelect
                        defaultValue={optionsDataSubtitle[0]}
                        onChange={onChangeSubtitles2}
                        options={optionsDataSubtitle}
                        getOptionLabel={getOptionLabelFormat}
                      />
                    </Col>
                    <Col
                      xs={12}
                      xl={6}
                      className="p-1 fw-bold text-center d-block d-sm-none"
                    >
                      Перевод
                    </Col>
                    <Col xs={12} xl={6}>
                      <CustomSelect
                        defaultValue={optionsDataFormatSubtitles[0]}
                        onChange={onChangeFormatSubtitles1}
                        options={optionsDataFormatSubtitles}
                        getOptionLabel={getOptionLabelFormat}
                      />
                    </Col>
                  </Row>
                  <Row className="text-start">
                    <Col
                      xs={12}
                      xl={6}
                      className="p-1 fw-bold text-center d-block d-sm-none"
                    >
                      Доп. субтитры
                    </Col>

                    <Col xs={12} xl={6}>
                      <CustomSelect
                        defaultValue={optionsDataSubtitle[0]}
                        onChange={onChangeSubtitles3}
                        options={optionsDataSubtitle}
                        getOptionLabel={getOptionLabelFormat}
                      />
                    </Col>
                    <Col
                      xs={12}
                      xl={6}
                      className="p-1 fw-bold text-center d-block d-sm-none"
                    >
                      Перевод
                    </Col>
                    <Col xs={12} xl={6}>
                      <CustomSelect
                        defaultValue={optionsDataFormatSubtitles[0]}
                        onChange={onChangeFormatSubtitles2}
                        options={optionsDataFormatSubtitles}
                        getOptionLabel={getOptionLabelFormat}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="fw-bold pt-4">
                <span onClick={closeModal}>Закрыть</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${isEven ? "greyBackground" : "whiteBackground"}`}>
        <Container fluid>
          <Row
            className="align-items-center fw-normal mt-1"
            style={{ textAlign: "left" }}
          >
            <Col xs={12} xl={1} className="p-0" style={MyStyleTitle}>
              {JSON.stringify(optionsDataTitle[0].label)}
            </Col>
            <Col xs={12} xl={2} className="p-1 video ">
              <CustomSelect
                className="form-select-lg"
                defaultValue={optionsDataVideo[0]}
                onChange={onChangeVideo}
                options={optionsDataVideo}
                getOptionLabel={getOptionLabelFormat}
              />
            </Col>
            <Col xs={12} xl={4} className="p-1">
              <CustomSelect
                className="form-select-lg"
                defaultValue={optionsDataSound[1]}
                onChange={onChangeAudio}
                options={optionsDataSound}
                getOptionLabel={getOptionLabelFormat}
              />
            </Col>
            <Col xs={12} xl={1} className="p-1">
              <CustomSelect
                className="form-select"
                defaultValue={optionsDataSubtitle[0]}
                onChange={onChangeSubtitles}
                options={optionsDataSubtitle}
                getOptionLabel={getOptionLabelFormat}
              />
            </Col>
            <Col xs={12} xl={2} className="p-1">
              <CustomSelect
                className="form-select"
                defaultValue={optionsDataFormat[0]}
                onChange={onChangeDataFormat}
                options={optionsDataFormat}
                getOptionLabel={getOptionLabelFormat}
              />
            </Col>
            <Col xs={12} xl={1} className="p-1">
              <CustomSelect
                className="form-select form-select-lg mb-3"
                defaultValue={optionsDataPriority[0]}
                onChange={onChangePriority}
                options={optionsDataPriority}
                getOptionLabel={getOptionLabelFormat}
              />
            </Col>
            <Col xs={12} xl={1} className="p-1 text-center">
              <button
                className="btn-sm"
                style={{ color: `${colorButtonSend}` }}
                disabled={disabledButtonSend}
                onClick={ButtonClick}
              >
                Code
              </button>
              <button className="btn-sm" onClick={openModal}>
                Modal
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WorkTable;