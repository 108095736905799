import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import WorkTable from "./Components/WorkTable";

import AppLoader from "./Components/AppLoader/AppLoader";
import TableTitle from "./Components/TableTitle";
import "bootstrap/dist/css/bootstrap.min.css";
import MyComponent from "./Components/MyComponent";


function App() {

  const [ResponseData, setResponseData] = useState();
  const [ResponseFormat, setResponseFormat] = useState();
  const [ResponseSound, setResponseSound] = useState();
  const [ResponseSubtitles, setResponseSubtitles] = useState();
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await axios.get(
        "https://api-dev.portal.idc.md/api/codec/list"
      );
      setResponseData(response.data.data);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        "https://api-dev.portal.idc.md/api/codec/format"
      );
      setResponseFormat(response.data.data);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        "https://api-dev.portal.idc.md/api/codec/sound"
      );
      setResponseSound(response.data.data);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        "https://api-dev.portal.idc.md/api/codec/subtitles"
      );
      setResponseSubtitles(response.data.data);
      // после последнего запроса убираем загрузчик
      setIsLoadingData(false);
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <div className="App">
      <TableTitle />
      {isLoadingData && <AppLoader />}
      {ResponseData &&
        ResponseFormat &&
        ResponseSound &&
        ResponseSubtitles &&
        ResponseData.map((el, index) => (
          <WorkTable
            key={index}
            index={index}
            data={el}
            format={ResponseFormat}
            formatSubtitles={ResponseSubtitles}
            formatSound={ResponseSound}
          />
        ))}
    </div>
  );
}

export default App;
